
import { Vue, Component, Watch } from 'vue-property-decorator';
import { storageKey } from '@/enums/localStorage';

@Component({
})

export default class extends Vue {
  private handleOrder() {
    if (window.localStorage.getItem(storageKey.pickUpPlace) == null) {
      this.$router.push({ name: 'Map' });
    } else {
      this.$router.push({ name: 'Order' });
    }
  }

  private handleGoDiary() {
    this.$router.push({ name: 'Diary' });
  }
}
